<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: "Conference",
  }

</script>
<style scoped>

</style>